/// <reference path="../app/typings/tsd.d.ts" />

//@ts-ignore
// console.log(`%cinitializing moss-piglet ${ENVIRONMENT} build (${GIT_HASH} -- ${GIT_BRANCH}), ${BUILD_DATE}`, 'font-size: 20px');

import * as React from 'react';
import dynamic from 'next/dynamic';
import GenericPage from '../app/components/generics/layout/generic-page';
import { Profiler } from 'react';


var rootInstance = null;
let _releaseStage = 'dev';

function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
    // Aggregate or log render timings...
    // console.log(`${id}'s ${phase} phase: ${JSON.stringify({ actualDuration, baseDuration, startTime, commitTime })}}`);
}


function App({ Component, pageProps, cookies }) {
    const V3AppContainer = dynamic(() => import('../app/containers/app/v3-app-container'), {
        ssr: false,
    })

    const V3Layout = dynamic(() => import('../app/containers/app/layout'), {
        ssr: false,
    })

    return <>
        <Profiler id="V3" onRender={onRender}>
        <GenericPage {...pageProps} cookies={cookies} >
            <V3Layout>
                <V3AppContainer />
            </V3Layout>
        </GenericPage>
        </Profiler>
    </>
}

App.getInitialProps = async (context: any) => {
    // Extract cookies from AppContext
    return {
        cookies: context.req.cookies
    }
}

export default App;
